<template>
    <div @click="action">
        <v-row class="pa-0 accuracy-table-row cursor-pointer align-center">
            <v-col cols="3" class="py-2 grey--text darken-2 text-capitalize">
                <v-row class="pa-0">
                    <v-col
                        cols="4"
                        class="pa-0 d-flex justify-center align-center"
                        align-self="center"
                    >
                        <UserAvatar
                            :user-name="$utils.getFullName(rowData.reader)"
                            :avatar="rowData.reader.avatar"
                        />
                    </v-col>
                    <v-col cols="8" class="pa-0 d-flex justify-start align-center">
                        <span class="ml-1">{{ $utils.getFullName(rowData.reader) }}</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-center">{{
                rowData.correctAnswers
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-center"
                >{{ rowData.incorrectAnswers }}
            </v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-center">{{
                rowData.totalAnswers
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2">{{
                `${rowData.accuracy}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2">{{
                `${rowData.accuracyIncorrectAnswers}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-right">{{
                `${rowData.accuracyLoc}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-right">{{
                `${rowData.accuracyIn}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-right">{{
                `${rowData.accuracyRv}${symbol}`
            }}</v-col>
        </v-row>
    </div>
</template>

<script>
import UserAvatar from '../../core/UserAvatar'

export default {
    name: 'AccuracyRow',
    components: { UserAvatar },
    props: {
        rowData: { type: Object, default: () => null },
        color: { type: String, default: '#f1ff1' },
        symbol: { type: String, default: '%' },
        seleced: { type: Boolean, default: false },
    },
    methods: {
        action() {
            this.$emit('row-action')
        },
    },
}
</script>

<style scoped>
.accuracy-table-row:hover {
    background: rgba(196, 196, 196, 0.45) 35%;
}
</style>
