import Vue from 'vue'
const logicShowAllMixin = Vue.extend({
    props: {
        tableData: { type: Array, default: () => [] },
    },
    data: () => ({
        showAll: false,
    }),
    computed: {
        items(){
            const _this = this;
            if(_this.showAll) return _this.tableData
            return Array.isArray(_this.tableData) ? _this.tableData.slice(0, 5) : []
        },
    },
    methods: {
        showAllRecords() {
            // @ts-ignore
            this.showAll = !this.showAll
        },
    },
})

export default logicShowAllMixin
