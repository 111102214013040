<template>
    <v-card
        class="card-border d-flex flex-column justify-start align-center elevation-0 border-radius-1 pb-0 w-100"
    >
        <v-container class="mb-5">
            <v-col cols="12">
                <v-row class="text-capitalize">
                    <v-col
                        cols="3"
                        class="primary--text font-size-18"
                        :style="{ color: `${mainColor} !important` }"
                        >{{ $t('students') }}</v-col
                    >
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">{{
                        $t('correct')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">{{
                        $t('incorrect')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-14 no-break-words">{{
                        $t('total')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">{{
                        $t('percentageCorrectAnswers')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">{{
                        $t('percentageWrongAnswers')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">{{
                        $t('home.simple_comprehesion_skills[0]')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">{{
                        $t('home.simple_comprehesion_skills[1]')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">{{
                        $t('home.simple_comprehesion_skills[2]')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12 no-break-words">
                        <download-excel :data="getItemsToExport(tableData)" type="csv" :name="`${filename}.csv`" :fields="swap(fileFields)">
                            <v-btn color="secondary" outlined rounded class="text-capitalize">
                                <v-icon class="mr-2">mdi-file-export-outline</v-icon>
                            </v-btn>
                        </download-excel>
                    </v-col>
                </v-row>
            </v-col>
            <v-divider></v-divider>
            <v-expansion-panels cols="12" class="d-flex flex-column" v-if="tableData.length" :readonly="hideActions">
                <v-expansion-panel v-for="(item, index) of items" :key="index" class="position-relative">
                    <v-expansion-panel-header :hide-actions="hideActions" class="expansion-panel-header">
                        <AccuracyRow
                            :row-data="item"
                            symbol="%"
                            :color="mainColor ? mainColor : color(item.accuracy)"
                            @row-action="setSelected(item.reader.id)"
                        />
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="px-7" justify="center" align-content="center">
                            <v-col cols="12" v-if="teksData(item.teksReportList).length">
                                <v-row class="grey--text darken-2 font-size-14">
                                    <v-col cols="4">{{ $t('code') }}</v-col>
                                    <v-col cols="2"> % {{ $t('accuracy') }}</v-col>
                                    <v-col cols="2">{{ $t('correct') }}</v-col>
                                    <v-col cols="2">{{ $t('incorrect') }}</v-col>
                                    <v-col cols="2">{{ $t('total') }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="d-flex flex-column text-center" v-else>
                                <span class="text-center" :style="{ color: `${mainColor}` }">{{
                                    $t('not_data')
                                }}</span>
                            </v-col>
                            <v-col cols="12">
                                <AccuracyTeksRow v-for="(row, i) in teksData(item.teksReportList)" :row-data="row" :key="i" />
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                    <div v-if="useDetails">
                        <StudentDetailCard
                            :reader="item.reader"
                            :user-id="item.reader.id"
                            :accuracy="item.accuracy"
                            v-if="selectedIndex === item.reader.id"
                            @exit="setSelected"
                        />
                    </div>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-container>
        <v-btn
            outlined
            rounded
            small
            color="secondary"
            class="show-button"
            style="z-index: 0;"
            @click="showAllRecords"
        >{{ showAll ? $t('library.show_minus') : `${$t('library.show_all')} (${tableData.length})` }}</v-btn
        >
    </v-card>
</template>

<script>
import AccuracyRow from './AccuracyRow'
import StudentDetailCard from '../../reporting/core/StudentDetailCard'
import logicShowAllMixin from "../../../mixins/logicShowAll";
import AccuracyTeksRow from "../../core/AccuracyTeksRow";

export default {
    name: 'AccuracyTable',
    props: {
        mainColor: { type: String, default: '' },
        symbol: { type: String, default: '%' },
        useDetails: { type: Boolean, default: true },
        hideActions: { type: Boolean, default: false },
        book: { type: Object, default: { title: ''} },
        assign: { type: Object, default: {startingDate: '', evaluationDate: ''} }
    },
    components: {
        AccuracyTeksRow,
        AccuracyRow,
        StudentDetailCard,
    },
    data() {
        return {
            selectedIndex: null,
            fileFields: {
                student: this.$t('students'),
                correctAnswers: this.$t('correct'),
                incorrectAnswers: this.$t('incorrect'),
                totalAnswers: this.$t('total'),
                accuracy: this.$t('percentageCorrectAnswers'),
                accuracyIncorrectAnswers: this.$t('percentageWrongAnswers'),
                accuracyLoc: this.$t('home.simple_comprehesion_skills[0]'),
                accuracyIn: this.$t('home.simple_comprehesion_skills[1]'),
                accuracyRv: this.$t('home.simple_comprehesion_skills[2]'),
            }
        }
    },
    methods: {
        teksData(teksReportList) {
            return teksReportList ?? [];
        },
        setSelected(id = 0) {
            this.selectedIndex = id
        },
        color(value = 0) {
            if (value <= 40) return '#514773'
            if (value >= 41 && value <= 59) return '#a48b00'
            return '#24dca9'
        },
        compare(a, b) {
            if (a.accuracy < b.accuracy) {
                return 1
            }
            if (a.accuracy > b.accuracy) {
                return -1
            }
            return 0
        },
        getItemsToExport(items) {
            let itemsToExport = [];
            if (items) {
                itemsToExport = items.map( (item) => {
                    return { 
                        student: `${item.reader.firstname} ${item.reader.lastname}`,
                        correctAnswers: item.correctAnswers,
                        incorrectAnswers: item.incorrectAnswers,
                        totalAnswers: item.totalAnswers,
                        accuracy: `${item.accuracy}%`,
                        accuracyIncorrectAnswers: `${item.accuracyIncorrectAnswers}%`,
                        accuracyLoc: `${item.accuracyLoc}%`,
                        accuracyIn: `${item.accuracyIn}%`,
                        accuracyRv: `${item.accuracyRv}%`,
                    }
                })
            }
            return itemsToExport
        },
        swap(obj){
            return Object.fromEntries(Object.entries(obj).map(a => a.reverse()))
        }
    },
    computed: {
        filename() {
            return `${this.book.title.replace(/\s/g,'_')}-${this.$moment(this.assign.startingDate).format('DD_MM')}-${this.$moment(this.assign.evaluationDate).format('DD_MM')}-questions`
        },
    },
    mixins: [logicShowAllMixin]
}
</script>

<style scoped>
.card-table {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 10%,
        rgba(196, 196, 196, 0.45) 35%,
        rgba(154, 162, 157, 0.62) 80%
    );
    bottom: 0;
}
.card-border {
    border: #e9e6f0 2px solid;
}

.expansion-panel-header:hover {
    background: transparent;
}

.no-break-words{
    overflow-wrap: normal;
}
</style>
