<template>
    <div class="position-absolute">
        <v-card
            color="white"
            outlined
            elevation="0"
            width="250"
            class="student-detail-card position-relative primary d-flex flex-column align-center justify-center"
            :loading="loading"
        >
            <v-btn
                small
                icon
                color="primary"
                elevation="2"
                class="primary lighten-2 white--text position-absolute btn-corner"
                @click="exitCard"
            >
                <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
            <UserAvatar
                :user-name="$utils.getFullName(reader)"
                :avatar="reader.avatar"
                size="8"
                class="position-absolute avatar-position"
                v-if="reader_data"
            />
            <v-container class="text-center pb-1 pt-8">
                <span class="white--text text-center pa-0 font-size-18">
                    {{ $utils.getFullName(reader) }}</span
                >
            </v-container>

            <v-container class="py-0" v-if="reader_data">
                <v-row class="px-3 py-0" align="center" justify="center">
                    <v-col cols="6" class="text-center">
                        <span class="subtitle-text text font-size-12 text-center">{{
                            reader_data.user.course.name
                        }}</span>
                    </v-col>
                    <v-col cols="6" class="text-center">
                        <span class="subtitle-text font-size-12 text-center text-capitalize">{{
                            `${$t('level')} ${reader_data.userGameStats.userLevel}`
                        }}</span>
                    </v-col>
                </v-row>
                <v-divider color="#9d95bb"></v-divider>
                <v-container class="py-0">
                    <v-row class="pa-0">
                        <v-col cols="8" class="d-flex justify-start align-start flex-column">
                            <span class="white--text font-size-14">{{
                                $t('reporting.reading_time')
                            }}</span>
                            <span class="subtitle-text font-size-14"
                                >{{ reader_data.hoursReads }}h</span
                            >
                        </v-col>
                        <v-col cols="4" class="d-flex justify-start align-start flex-column">
                            <span class="white--text font-size-14">{{
                                $t('reporting.reads')
                            }}</span>
                            <span class="subtitle-text font-size-14">{{
                                reader_data.booksReadsCounter
                            }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0">
                        <v-col cols="12" class="d-flex justify-start align-start flex-column">
                            <span class="white--text font-size-14">{{
                                $t('reporting.currently_reading')
                            }}</span>
                            <span class="subtitle-text font-size-14">{{
                                reader_data.book.title
                            }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0">
                        <v-col cols="12" class="d-flex justify-start align-start flex-column">
                            <span class="white--text font-size-14">{{
                                $t('reporting.compared_performance')
                            }}</span>
                            <span class="subtitle-text font-size-14">{{ perfomance_msg }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="pa-0">
                        <v-col cols="12" class="d-flex justify-center align-center flex-column">
                            <v-btn
                                small
                                outlined
                                rounded
                                color="white"
                                class="text-capitalize"
                                @click="redirect"
                                >{{ $t('reporting.full_profile') }}</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
            <v-container v-else> <br /><br /><br /> </v-container>
        </v-card>
    </div>
</template>

<script>
import courseBookStats from '../../../services/courseBookStats'
import UserAvatar from '../../core/UserAvatar'
export default {
    name: 'StudentDetailCard',
    components: { UserAvatar },
    props: {
        reader: { type: Object, default: () => null },
        userId: { type: [String, Number], default: '' },
        accuracy: { type: Number, default: 0 },
    },
    async mounted() {
        this.percently = this.$utils.percentRank(
            [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            this.accuracy
        )
        this.loading = true
        const response = await courseBookStats
            .getBasicUserData(this.userId)
            .catch((e) => console.error(e))
        this.reader_data = response.content
        this.loading = false
    },
    data: () => ({
        loading: false,
        reader_data: null,
        percently: 0,
    }),
    methods: {
        exitCard() {
            this.$emit('exit')
        },
        redirect() {
            this.$router.push(
                `/teacher/report-skill-level/student-performance/${this.userId}/${this.accuracy}`
            )
        },
    },
    computed: {
        perfomance_msg() {
            return this.$t('reporting.performance_msg', [
                this.$utils.upperFirstLetter(this.reader.firstname),
                this.percently,
            ])
        },
    },
}
</script>

<style scoped>
.student-detail-card {
    z-index: 2;
    top: -10vh;
    left: 25vh;
}

.subtitle-text {
    color: #9d95bb;
}

.btn-corner {
    right: -1vh;
    top: -1vh;
}

.avatar-position {
    top: -4vh;
}
</style>
